import React from 'react';
import * as AccordionPrimitive from '@radix-ui/react-accordion';
import { Accordion as AccordionType } from '@/types';
import clsx from 'clsx';
import Accordion from './Accordion';

type Props = {
  className?: string;
  accordions: AccordionType[];
};

function Accordions(props: Props) {
  const { accordions, className } = props;

  return (
    <AccordionPrimitive.Root className={clsx('flow', className)} collapsible type='single'>
      {accordions.map(accordion => (
        <Accordion key={accordion.title} title={accordion.title} text={accordion.text} />
      ))}
    </AccordionPrimitive.Root>
  );
}

export default Accordions;
