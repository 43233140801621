import { Accordion as AccordionType } from '@/types';
import AccordionsComponent from '@components/Accordions';

type Props = {
  accordions: AccordionType[];
  title?: string;
};

function Accordion(props: Props) {
  const { accordions, title } = props;

  return (
    <div className='streamfield accordions'>
      {title ? <h2 className='accordions__header'>{title}</h2> : null}

      <AccordionsComponent className='' accordions={accordions} />
    </div>
  );
}

export default Accordion;
