import { WagtailStreamfieldImage } from '@/types';
import SliderComponent from '@components/Slider';
import { addBaseUrl } from '@utils/url';
import Image from 'next/image';

type Props = { images: WagtailStreamfieldImage[] };

function Slider(props: Props) {
  const { images } = props;
  return (
    <div className='streamfield slider'>
      <SliderComponent>
        {images.map(image => (
          <Image
            key={image.id}
            src={addBaseUrl(image.file.src)}
            alt={image.file.alt}
            width={image.file.width}
            height={image.file.height}
          />
        ))}
      </SliderComponent>
    </div>
  );
}
export default Slider;
