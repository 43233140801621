import Link from 'next/link';
import React from 'react';

import { removeBaseUrl } from '@/utils/url';

type Props = {
  title: string;
  text: string;
  button_page: { title: string; full_url: string } | null;
  button_url: string | null;
  button_text: string;
};

function CTA(props: Props) {
  const {
    button_page: buttonPage,
    button_url: buttonUrl,
    button_text: buttonText,
    title,
    text,
  } = props;

  return (
    <div className='streamfield cta'>
      {buttonPage ? <Link href={removeBaseUrl(buttonPage.full_url)}>{buttonText}</Link> : null}
      {buttonUrl ? <Link href={buttonUrl}>{buttonText}</Link> : null}
    </div>
  );
}

export default CTA;
