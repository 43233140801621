import Head from 'next/head';

type Props = {
  todos: string[];
};

function print() {
  window.print();
}

function TodoList(props: Props) {
  const { todos } = props;

  return (
    <>
      <Head>
        {/*  eslint-disable-next-line @next/next/no-css-tags */}
        <link rel='stylesheet' href='/print.css' />
      </Head>
      <div className='streamfield text-block bg-blue clr-white todolist'>
        <div className='content'>
          <div className='todolist__icon'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              xmlSpace='preserve'
              fillRule='evenodd'
              strokeLinejoin='round'
              strokeMiterlimit='2'
              clipRule='evenodd'
              viewBox='0 0 48 48'
            >
              <path
                fill='#fff'
                fillRule='nonzero'
                d='M21.55 41.3H9c-.8 0-1.5-.3-2.1-.9-.6-.6-.9-1.3-.9-2.1V9.2c0-.8.26-1.5.78-2.1.51-.6.95-.9 1.32-.9h10.1c.23-1.17.8-2.13 1.73-2.88A4.87 4.87 0 0 1 23.1 2.2c1.2 0 2.26.38 3.17 1.13.92.75 1.5 1.7 1.73 2.87h10.1c.8 0 1.5.3 2.1.9.6.6.9 1.3.9 2.1v10.15h-3V9.2h-5.3v6.5H14.3V9.2H9v29.1h12.55v3ZM31 40.05l-8-8 2.15-2.15L31 35.75 42.95 23.8l2.15 2.15L31 40.05ZM24 9a2 2 0 0 0 1.43-.57c.38-.39.57-.86.57-1.43s-.2-1.04-.57-1.42A1.94 1.94 0 0 0 24 5a1.97 1.97 0 0 0-2 2 2 2 0 0 0 .57 1.43c.39.38.86.57 1.43.57Z'
              />
            </svg>
          </div>
          <h2>Checkliste. An alles gedacht?</h2>
          <div className='flow'>
            <p>
              <strong>
                Hier noch einmal alle wichtigen Veranstaltungstipps als Checkliste zum Abhaken.
              </strong>
            </p>
          </div>
          {todos.map(todo => (
            <div key={todo}>
              <label className='styledcheckbox' htmlFor={todo}>
                <input type='checkbox' id={todo} name={todo} />
                {todo}
              </label>
            </div>
          ))}
          <div className='text-center'>
            <button className='button' onClick={() => print()}>
              drucken
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default TodoList;
