import Configurator from '@components/Configurator';

type Props = {
  title: string;
};

function ConfiguratorBlock(props: Props) {
  const { title } = props;

  return (
    <div className='streamfield configurator-block'>
      {title ? <h2 className='configurator__header'>{title}</h2> : null}

      <Configurator />
    </div>
  );
}

export default ConfiguratorBlock;
