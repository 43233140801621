import React from 'react';
import { FormWrapper } from './FormWrapper';

type PeopleCountData = {
  persons: string;
};

type Props = PeopleCountData & {
  updateFields: (fields: Partial<PeopleCountData>) => void;
};

function PeopleCount({ persons, updateFields }: Props) {
  return (
    <FormWrapper title='Anzahl der Personen'>
      <label htmlFor='persons' className='styledSelect'>
        <select
          name='persons'
          id='persons'
          value={persons}
          onChange={e => updateFields({ persons: e.target.value })}
        >
          <option value='50'>50 Personen</option>
          <option value='75'>75 Personen</option>
          <option value='100'>100 Personen</option>
          <option value='125'>125 Personen</option>
          <option value='150'>150 Personen</option>
          <option value='175'>175 Personen</option>
          <option value='200'>200 Personen</option>
        </select>
      </label>
    </FormWrapper>
  );
}

export default PeopleCount;
