function calculate_price(pcount: string, stuehle: string) {
  const count_persons = parseInt(pcount, 10);
  let furniture = 0;
  if (stuehle === 'Stehtische') {
    furniture = 0.75;
  } else if (stuehle === 'Bierzelt') {
    furniture = 1;
  } else {
    furniture = 2;
  }
  const price = count_persons * furniture * 16;
  return price;
}

function calculate_qm(pcount: string, stuehle: string) {
  const count_persons = parseInt(pcount, 10);
  let furniture = 0;
  if (stuehle === 'Stehtische') {
    furniture = 0.75;
  } else if (stuehle === 'Bierzelt') {
    furniture = 1;
  } else {
    furniture = 2;
  }
  const qm = count_persons * furniture;
  return qm;
}

export { calculate_price, calculate_qm };
