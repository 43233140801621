import { WagtailPage, WagtailSvg } from '@/types';
import RawHtml from '@components/RawHtml';
import { addBaseUrl, removeBaseUrl } from '@utils/url';
import clsx from 'clsx';
import Image from 'next/image';
import Link from 'next/link';

type Props = {
  title: string;
  text: string;
  link: WagtailPage | null;
  bg_color: 'white' | 'blue';
  color: 'white' | 'red' | 'black';
  textcenter: boolean;
  textcenter2: boolean;
  icon: WagtailSvg | null;
  buttontext: string | null;
  small_width?: boolean;
};

function TextBlock(props: Props) {
  const {
    title,
    text,
    bg_color,
    color,
    link,
    small_width,
    textcenter,
    textcenter2,
    icon,
    buttontext,
  } = props;

  return (
    <div
      className={clsx('streamfield', 'text-block', `bg-${bg_color}`, `clr-${color}`, {
        'text-block--small-width': small_width,
        'text-block--with-icon': icon,
      })}
    >
      {icon ? (
        <Image
          className='text-block__icon'
          src={addBaseUrl(icon.file)}
          alt={icon.title}
          width={64}
          height={64}
        />
      ) : null}
      <div className={textcenter2 ? 'content text-center' : 'content'}>
        <h2 className={textcenter ? 'text-center' : ''}>{title}</h2>

        <RawHtml html={text} />
        {link ? (
          <Link className='button' href={removeBaseUrl(link.full_url)}>
            {buttontext || 'Mehr erfahren'}
          </Link>
        ) : null}
      </div>
    </div>
  );
}

export default TextBlock;
