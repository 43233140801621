import { calculate_qm, calculate_price } from '@utils/form';
import React from 'react';
import { FormWrapper } from './FormWrapper';

type Props = {
  persons: string;
  bestuhlung: string;
};

function Results(props: Props) {
  return (
    <FormWrapper title='Wir empfehlen eine Zeltgröße von'>
      <div className='text-center'>
        <div className='result'>{calculate_qm(props.persons, props.bestuhlung)} m²</div>

        <div className='price'>ab {calculate_price(props.persons, props.bestuhlung)}€</div>
        <p>
          Bei ungefähr {props.persons} Personen und der folgenden Bestuhlung: {props.bestuhlung}
        </p>
      </div>
    </FormWrapper>
  );
}

export default Results;
