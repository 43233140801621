import React, { FormEvent, useCallback, useState } from 'react';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import { calculate_price, calculate_qm } from '@utils/form';
import PeopleCount from './PeopleCount';
import Furniture from './Furniture';
import Results from './Results';
import { useMultistepForm } from './useMultistepForm';

type FormData = {
  persons: string;
  bestuhlung: string;
};

const INITIAL_DATA: FormData = {
  persons: '100',
  bestuhlung: 'stehtische',
};

type Props = {};

function Configurator(props: Props) {
  const [data, setData] = useState(INITIAL_DATA);
  const router = useRouter();

  const updateFields = useCallback((fields: Partial<FormData>) => {
    setData(prev => {
      return { ...prev, ...fields };
    });
  }, []);

  const { steps, currentStepIndex, step, isFirstStep, isLastStep, back, next } = useMultistepForm([
    <PeopleCount {...data} updateFields={updateFields} key='people' />,
    <Furniture {...data} updateFields={updateFields} key='furniture' />,
    <Results {...data} key='results' />,
  ]);

  function onSubmit(e: FormEvent) {
    e.preventDefault();
    if (!isLastStep) return next();
    const quadratmeter = calculate_qm(data.persons, data.bestuhlung).toString();
    const nachricht = `Anfrage über eine Zeltgröße von ${quadratmeter} m² bei ungefähr ${data.persons} Personen und der folgenden Bestuhlung: ${data.bestuhlung}`;

    return router.push(
      {
        pathname: '/anfrage',
        query: { nachricht },
      },
      '/anfrage',
    );
  }

  return (
    <form className='multisiteForm' onSubmit={onSubmit}>
      <div
        className={clsx(
          'multisiteForm__counter',
          `multisiteForm__counter--step${currentStepIndex + 1}`,
        )}
      >
        <div>
          <span className=''>{currentStepIndex + 1}</span> von {steps.length}
        </div>
        <progress id='file' max={steps.length} value={currentStepIndex + 1} />
      </div>
      <div className='multisiteForm__content'>{step}</div>
      <div className={clsx('multisiteForm__footer', isFirstStep && 'rev')}>
        {!isFirstStep && (
          <button type='button' className='button button-thin' onClick={back}>
            Zurück
          </button>
        )}
        <button className='button ' type='submit'>
          {isLastStep ? 'Fertig' : 'Weiter'}
        </button>
      </div>
    </form>
  );
}

export default Configurator;
