import React from 'react';
import { FormWrapper } from './FormWrapper';

type FurnitureData = {
  bestuhlung: string;
};

type Props = FurnitureData & {
  updateFields: (fields: Partial<FurnitureData>) => void;
};

function Furniture({ bestuhlung, updateFields }: Props) {
  return (
    <FormWrapper title='Welche Bestuhlung planen Sie?'>
      <div className='styledRadio'>
        <label htmlFor='Stehtische' className='form-control'>
          <input
            name='Stehtische'
            type='radio'
            value='Stehtische'
            id='Stehtische'
            checked={bestuhlung === 'Stehtische'}
            onChange={e => updateFields({ bestuhlung: e.target.value })}
          />
          Stehtische
        </label>

        <label htmlFor='Bierzelt' className='form-control'>
          <input
            name='Bierzelt'
            type='radio'
            value='Bierzelt'
            id='Bierzelt'
            checked={bestuhlung === 'Bierzelt'}
            onChange={e => updateFields({ bestuhlung: e.target.value })}
          />
          Bierzeltgarnitur
        </label>

        <label htmlFor='festlich' className='form-control'>
          <input
            name='festlich'
            type='radio'
            value='festlich'
            id='festlich'
            checked={bestuhlung === 'festlich'}
            onChange={e => updateFields({ bestuhlung: e.target.value })}
          />
          festliche Bestuhlung
        </label>
      </div>
    </FormWrapper>
  );
}

export default Furniture;
