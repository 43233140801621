import ContactPerson from '@components/ContactPerson';
import { ContactPerson as TContactPerson } from '@/types';

type Person = {
  subsidiary?: string;
  contactperson: TContactPerson[];
};

type Props = {
  title?: string;
  persons: Person[];
};

function ContactPersons(props: Props) {
  const { title, persons } = props;
  return (
    <div className='streamfield contact-persons'>
      {title ? <h2>{title}</h2> : null}

      {persons.map(person => {
        return (
          <div key={person.subsidiary} className='cs'>
            <p className='h3 clr-b'>{person.subsidiary}</p>
            <div className='cp-wrapper'>
              {person.contactperson.map(contactperson => (
                <ContactPerson key={contactperson.name} {...contactperson} />
              ))}
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default ContactPersons;
