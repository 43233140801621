import Accordion from './Accordion';
import ConfiguratorBlock from './Configurator';
import ContactPersons from './ContactPersons';
import CTA from './CTA';
import ImageBlock from './ImageBlock';
import Links from './Links';
import Message from './Message';
import Richtext from './Richtext';
import Slider from './Slider';
import Table from './Table';
import TextBlock from './TextBlock';
import TodoList from './TodoList';

const streamfields = {
  cta: CTA,
  links: Links,
  message: Message,
  full_richtext: Richtext,
  simple_richtext: Richtext,
  table: Table,
  code: Richtext,
  text: TextBlock,
  image: ImageBlock,
  accordion: Accordion,
  slider: Slider,
  todolist: TodoList,
  configurator: ConfiguratorBlock,
  contactpersons: ContactPersons,
};

export type Streamfields = keyof typeof streamfields;

export default streamfields;
