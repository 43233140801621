import { WagtailStreamfieldImage } from '@/types';
import Debug from '@components/Debug';
import { addBaseUrl } from '@utils/url';
import Image from 'next/image';

type Props = {
  image: WagtailStreamfieldImage;
  image_caption: string;
};

function ImageBlock(props: Props) {
  const { image, image_caption } = props;

  return (
    <div className='streamfield image-block'>
      <div className='image-block-wrapper'>
        <Image
          src={addBaseUrl(image.file.src)}
          fill
          alt={image.file.alt}
          sizes='(min-width: 50rem) 33vw,
        100vw'
        />
      </div>
      {image_caption ? <div className='caption'>{image_caption} </div> : null}
    </div>
  );
}

export default ImageBlock;
