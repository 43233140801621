import Link from 'next/link';
import Image from 'next/image';
import { ContactPerson as TContactPerson } from '@/types';
import { addBaseUrl, removeBaseUrl } from '@/utils/url';

function ContactPerson(props: TContactPerson) {
  const { image, name, position, phone, link } = props;

  return (
    <div className='cp'>
      <Image src={addBaseUrl(image.file.src)} alt='' width={440} height={440} />
      <p className='h4 clr-b cp__name'> {name}</p>
      {position ? <p className='cp__pos'> {position}</p> : null}
      {phone ? <p className='cp__ph'> {phone}</p> : null}
      {link ? (
        <Link href={removeBaseUrl(link.full_url)} className='button button-thin'>
          Kontakt
        </Link>
      ) : null}
    </div>
  );
}

export default ContactPerson;
