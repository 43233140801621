import React from 'react';

type Props = {
  table: {
    data: string[][];
    first_row_is_table_header: boolean;
    first_col_is_header: boolean;
    table_caption?: string;
  };
};

function Table(props: Props) {
  const {
    /* eslint-disable-next-line @typescript-eslint/naming-convention */
    table: { data, first_row_is_table_header, first_col_is_header, table_caption },
  } = props;

  return (
    <div className='streamfield table'>
      <table>
        {table_caption ? <caption>{table_caption}</caption> : null}
        {first_row_is_table_header ? (
          <thead>
            <tr>
              {data[0].map(th => (
                <th key={th}>{th}</th>
              ))}
            </tr>
          </thead>
        ) : null}
        <tbody>
          {data
            .map((row, index) => {
              if (first_row_is_table_header && index === 0) {
                return null;
              }
              return row;
            })
            .filter(Boolean)
            .map((row, index) => (
              /* eslint-disable-next-line react/no-array-index-key */
              <tr key={index}>
                {row?.map((td, i) => {
                  return first_col_is_header && i === 0 ? (
                    /* eslint-disable-next-line react/no-array-index-key */
                    <th key={`${i}${td}`}>{td}</th>
                  ) : (
                    /* eslint-disable-next-line react/no-array-index-key */
                    <td key={`${i}${td}`}>{td}</td>
                  );
                })}
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
}

export default Table;
